import axios from '@axios'

export default {
  namespaced: true,
  state: {
    calendarOptions: [
      {
        color: 'danger',
        label: 'Personal',
      },
      {
        color: 'primary',
        label: 'Business',
      },
      {
        color: 'warning',
        label: 'Family',
      },
      {
        color: 'success',
        label: 'Holiday',
      },
      {
        color: 'info',
        label: 'ETC',
      },
    ],
    selectedCalendars: ['Personal', 'Business', 'Family', 'Holiday', 'ETC'],
  },
  getters: {},
  mutations: {
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val
    },
  },
  actions: {
    // fetchEvents(ctx, { calendars }) {
      fetchEvents() {
        return new Promise((resolve, reject) => {
        axios
          .get(`bookings`
/*              , {
            params: {
              calendars: calendars.join(','),
            },
          }*/
          )
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    addEvent(ctx, { event }) {
      let date = new Date(event.start)
      return new Promise((resolve, reject) => {
        axios
          .post(`bookings`, {
            "name": event.title,
            "email": event.extendedProps.email,
            "phone": event.extendedProps.phone,
            "date": (date.getMonth() + 1) + '/' + date.getDate() + '/' +  date.getFullYear(),
            "time": date.toLocaleTimeString().replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3"),
            "service": event.extendedProps.service,
            "persons": event.extendedProps.persons,
            "note": event.extendedProps.note,
            "admin_note": event.extendedProps.admin_note
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateEvent(ctx, { event }) {
      let date = new Date(event.start)

      return new Promise((resolve, reject) => {
        axios.put(`bookings/${event.id}`, {
          "id": event.id,
          "name": event.title,
          "email": event.extendedProps.email,
          "phone": event.extendedProps.phone,
          "date": (date.getMonth() + 1) + '/' + date.getDate() + '/' +  date.getFullYear(),
          "time": date.toLocaleTimeString().replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3"),
          "service": event.extendedProps.service,
          "persons": event.extendedProps.persons,
          "note": event.extendedProps.note,
          "active": event.extendedProps.active,
          "admin_note": event.extendedProps.admin_note
        })
          .then(response => {
              resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    removeEvent(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`bookings/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
