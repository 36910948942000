<template>
  <b-row>
    <b-col cols="12">
      <good-table-basic></good-table-basic>
    </b-col>
  </b-row>
</template>

<script>
import {BCol, BRow} from 'bootstrap-vue'
import GoodTableBasic from "../../../table/vue-good-table/GoodTableBasic";

export default {
  name: "Product.vue",
  components: {GoodTableBasic, BRow, BCol}
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>